<template>
  <main>

    <center-title title="临时产品方案"></center-title>
    <router-link to="/selectmygoodslist" class="el-button whtie submit" 
      >产品浏览</router-link>

   
      <router-link to="/mygoodslist" class="el-button whtie submit"  style="margin-bottom: 2%;"
      >产品方案</router-link>
      <router-link to="/existinglist" class="el-button whtie submit"  style="margin-bottom: 2%;"
      >临时产品方案</router-link>

      <!-- <router-link to="/savegoodslist" @click.native="saveGoodsList()" class="el-button whtie submit"  style="margin-bottom: 2%;margin-right: 10%;"
      >制作商品集</router-link> -->
    <article class="bg-white">
   
      <div class="core condition">
     
     
        <div class="pro-main row-between">
       
          <div class="pro-list" style="width: 100%;">
         
            <make-storeList v-if="goodsList.length" width="236" height="425" ph="17" pv="17" titleSize="14" margin="6"
              :priceSize="20" :oldpriceShow="false" class="list mb10" :prolists="true" :goodsList="goodsList">
           
            </make-storeList>
            <make-pagination :orderObject="orderObject" v-if="goodsList.length">
            </make-pagination>
            <el-empty description="暂无商品" class="mt30 flex1" v-else></el-empty>
          </div>
        </div>
      </div>
    </article>
    <!-- <button type="primary" class="el-button  " style="background-color: burlywood; flood-color:black;" @click="makeppt('ruleForm')">
        制作PPT
      </button> -->
      <!-- <button type="primary" class="el-button  " style="background-color: rgb(71, 165, 241); flood-color:rgb(23, 197, 130);" @click="makepdf('ruleForm')">
        添加产品
      </button>
      <button type="primary" class="el-button  " style="background-color: rgb(16, 243, 9); flood-color:black;" @click="makepdf('ruleForm')">
        制作PDF
      </button> -->


          <el-button type="primary" class="whtie submit" @click="addGoods()">添加产品</el-button>
          <el-button type="primary" class="whtie submit" @click="makepdf('ruleForm')">制作PDF</el-button>
   
      
  </main>
</template>
<script>
import MakeBreadCrumb from "@/components/MakeBreadCrumb";
import MakeStoreList from "@/components/MakePpt/edit_existing_list_index";
import CenterTitle from "../components/CenterTitle";
export default {
  name: "",
  props: {},
  components: {
    "make-breadcrumb": MakeBreadCrumb,
    "make-storeList": MakeStoreList,
    "center-title": CenterTitle,
  },
  data() {
    return {
      categoryList: [],
      categoryOne: 0, //一级分类
      categoryTwo: 0, //二级分类
      categoryThree: 0, //三级分类
      count: 0, //总条数
      goodsList: [], //商品列表
      page_no: 1,
      currentPage: 0, //当前页
      // 筛选条件
      screen: {
        default: true, //综合
        sales: "", //销量
        price: "", //价格
      },
      screenCurrent: 0,
      categoryId: "", //当前筛选ID
      hotData: [], //热门列表
      qualityData: [], //精品列表
      orderObject: {},
      keyword: '',
      hdsx: {
        id: 0,
        name: '全部'
      }
    };
  },
  computed: {},
  watch: {},
  methods: {

    addGoods(){
      let id= this.$route.query.id;  
 
    // window.location.href ="/selectmygoodslist?plan_id="+id;
    window.sessionStorage.setItem("plan_id", id);


    this.$router.push('/selectmygoodslist');



       // 直接调用$router.push 实现携带参数的跳转
  //  this.$router.push({ path: '/selectmygoodslist/${'+id+'}'  })
  },
    setHdsx(command) {
      if (command == 3) {
        this.hdsx.id = 3
        this.hdsx.name = '满减活动'
      } else if (command == 2) {
        this.hdsx.id = 2
        this.hdsx.name = '限时折扣'
      } else {
        this.hdsx.id = 0
        this.hdsx.name = '全部'
      }
      this.getGoodsList(this.categoryId)
    },
   
    /**
     * @description 获取商品列表
     * @returns void
     */
  
    getGoodsList(id) {
       
      this.$api
        .getExistingListApi({
          goods_list_id: this.id,
          page_size: 20,
          activity_type: this.hdsx.id,
          page_no: this.page_no,
          sort_by_sales: this.screen.sales,
          sort_by_price: this.screen.price,
          keyword: this.keyword
        })
        .then((res) => {
          let { lists, count, page_size: size, page_no: page } = res.data;
          this.orderObject = res.data;
          this.orderObject = {
            page,
            size,
            count,
            lists,
          };
          this.goodsList = lists;
            console.log(this.goodsList);
        });
    },
    makeppt(){
     let id= this.$route.query.id;  
      this.$api.getGoodsListTopptApi({goods_list_id:id});      
    },
    makepdf(){
     let id= this.$route.query.id;  
      this.$api.getGoodsListTopdfApi({goods_list_id:id}).then((res)=>{
        // if (res.code == 1) {
			  //       this.$message({
			  //       	message: res.msg,
			  //       	type: "success",
			  //       	onClose: () => {

        //           window.location.href =res.msg;
                  
			  //       		this.$router.go(-1);
			  //       	},
			  //       });
			  //     }

      console.log(res);

        let fileName = res.data.name
       //download(res, fileName);

     

       const a = document.createElement('a')
				a.href = res.msg
				console.log(a.href)
				a.setAttribute('download',fileName)
				a.click()
				a.remove()
 

        
      });      
    }
   
  
  },
  created() {
    this.id = this.$route.query.id;
 
    this.getGoodsList(this.id);
  },
  mounted() { },
  beforeDestroy() { },

 
  
};

const download = (res, filename) => {




  // 创建blob对象，解析流数据
  const blob = new Blob([res.msg]);
  const a = document.createElement("a");
  // 兼容webkix浏览器，处理webkit浏览器中href自动添加blob前缀，默认在浏览器打开而不是下载
  const URL = window.URL || window.webkitURL;
  // 根据解析后的blob对象创建URL 对象
  const herf = URL.createObjectURL(blob);
  // 下载链接
  console.log(res.msg);
  a.href = res.msg;
  // 下载文件名,如果后端没有返回，可以自己写a.download = '文件.pdf'
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  // 在内存中移除URL 对象
  window.URL.revokeObjectURL(herf);
};

</script>
<style lang='scss' scoped>
main {
  .core {
    width: 1170px;
  }

  .hot {
    padding: 33px 0;

    .title {
      width: 46px;
      height: 150px;
      padding: 28px 14px 0;
    }

    .hot-list {
      width: 1113px;
      .hot-item {
        border: $-solid-border;
        width: 359px;
        height: 150px;
        margin-right: 18px;
        padding: 20px;
        align-items: normal;

        &:last-child {
          margin-right: 0;
        }

        .item-pro {
          width: 140px;
          height: 108px;
          flex-shrink: 0;
        }

        .item-text {
          align-items: normal;

          .text-title {
            line-height: 20px;
            height: 40px;
          }

          .cart-box {
            width: 90px;
            height: 32px;
            border: $-solid-cartborder;
            cursor: pointer;
          }
        }
      }
    }
  }

  .condition {
    padding-top: 20px;
    padding-bottom: 40px;

    .condition-box {
      padding: 0px 20px;
      border: $-solid-border;

      .condition-t {
        height: 54px;
        border-bottom: $-dashed-border;

        .title {
          margin-right: 35px;
        }

        .t-content {
          padding: 0 8px;
          height: 28px;
          border: $-solid-border;
        }
      }

      .condition-b {
        padding: 15px 0;
        align-items: flex-start;
        line-height: 1.8;

        .title {
          margin-right: 43px;
          flex-shrink: 0;
        }

        .brand-list {
          span {
            margin-right: 60px;
            cursor: pointer;

            &.active,
            &:hover {
              color: $-color-primary;
            }
          }
        }
      }
    }
  }
}

.pro-main {
  align-items: flex-start;

  .recommend-list {
    width: 177px;

    .recommend-item {
      border: $-solid-border;
      padding: 0 10px;

      .title {
        height: 45px;
        line-height: 45px;
        border-bottom: $-solid-border;
      }

      /deep/.list {
        margin: 0;
        padding: 0 0 10px;

        .store-item {
          box-shadow: none;

          &:hover {
            .item-title {
              color: $-color-primary;
            }
          }

          .item-title {
            margin-bottom: 0;
          }

          .item-pro {
            margin-bottom: 5px;
          }
        }
      }
    }
  }

  .pro-list {
    width: 980px;

    .pro-condition {
      height: 46px;
      background: #f3f5f8;
      border: $-solid-border;

      ul {
        li {
          padding: 0 15px;
          cursor: pointer;

          span {
            margin-right: 5px;
          }

          .rotate {
            transform: rotate(180deg);
          }

          &.active {
            color: $-color-primary;
          }

          &.price-box {
            .arrow {
              position: relative;
              top: 02.5px;
            }

            em {
              transform: rotate(90deg);
              line-height: 1;
              font-size: 14px;
              width: 10px;
              height: 8px;
              color: #9e9e9e;
              font-weight: bold;
              background-image: url("~@/assets/icon/icon-arrow1.png");
              background-size: contain;
              background-repeat: no-repeat;

              &.active {
                color: $-color-primary;
                background-image: url("~@/assets/icon/icon-arrow10.png");
              }

              &:first-child {
                transform: rotate(270deg);
                margin-bottom: 5px;
              }
            }
          }
        }
      }

      .input-box {
        height: 30px;
        padding-right: 33px;

        input {
          border: 1px solid #d8d8d8;
          border-right: none;
          background-color: $-color-white;
          padding: 0 15px;
          height: inherit;
          width: 137px;
        }

        button {
          width: 73px;
          height: 30px;
          color: $-color-white;
          cursor: pointer;
        }
      }
    }

    /deep/ .list {
      padding-bottom: 20px;

      .store-item {
        .item-pro {
          margin-bottom: 10px;
        }

        .item-title {
          margin-bottom: 5px;
        }

        .item-cart {
          margin-top: 10px;
        }
      }
    }
  }
}
</style>