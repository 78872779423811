var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',[_c('div',{staticClass:"store-list row wrap",style:({ margin: -Number(_vm.margin) + 'px' })},_vm._l((_vm.goodsList),function(item,index){return _c('router-link',{key:index,staticClass:"store-item",style:({
        margin: _vm.margin + 'px',
        width: _vm.width + 'px',
        height: 360+ 'px',
        padding: _vm.ph + 'px ' + _vm.pv + 'px 0',
       
      }),attrs:{"to":``},nativeOn:{"click":function($event){return _vm.test()}}},[(_vm.showDel)?_c('div',{staticClass:"item-del",on:{"click":function($event){$event.preventDefault();return _vm.delToday(item.lsid)}}},[_c('img',{staticClass:"default",attrs:{"src":require("@/assets/icon/icon-del1.png"),"alt":""}}),_c('img',{staticClass:"active",attrs:{"src":require("@/assets/icon/icon-del2.png"),"alt":""}})]):_vm._e(),_c('div',{staticClass:"item-pro"},[_c('el-image',{staticClass:"img",attrs:{"src":item.image,"fit":"cover","lazy":""}})],1),(_vm.prolists)?_c('div',{staticClass:"item-imglist row mb10"},_vm._l((item.goods_image),function(items,indexs){return _c('el-image',{key:indexs,staticClass:"img",attrs:{"src":_vm.baseUrl + items.uri,"fit":"cover","lazy":""}})}),1):_vm._e(),_c('div',{staticClass:"item-title line2 normal",style:({ fontSize: _vm.titleSize + 'px' })},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"item-price"},[_c('make-priceformat',{staticClass:"mr10",attrs:{"price":item.min_price,"subscriptSize":_vm.priceSize,"firstSize":_vm.priceSize,"secondSize":_vm.priceSize}}),(_vm.oldpriceShow)?_c('make-priceformat',{attrs:{"price":item.market_price,"subscriptSize":_vm.oldpriceSize,"firstSize":_vm.oldpriceSize,"secondSize":_vm.oldpriceSize,"color":"#999999"}}):_vm._e()],1)])}),1)])
}
var staticRenderFns = []

export { render, staticRenderFns }